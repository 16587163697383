import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import * as actions from 'Actions';
import { HelpCircle } from 'Utils/SvgIcons';
import { calculateBillingFields } from 'HelperFunctions/Billing';
import { capitalizeFirstLetter } from 'HelperFunctions/general';
import DetailedAmountPaid from '../transactions/DetailedAmountPaid';
import DetailedAmountRefunded from '../transactions/DetailedAmountRefunded';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { selectCheckPermission } from '../../helper_functions/locations';

class RentalBilling extends Component {
  static defaultProps = {
    ownerView: false,
  };

  render() {
    const {
      slim,
      rental,
      ownerView,
      secondary,
      printFill,
      checkPermission,
    } = this.props;
    const { total, netEarnings } = calculateBillingFields(rental);
    const taxableFeeTotal = rental.taxableFees.reduce((acc, fee) => {
      acc += Number(fee.amount) * (fee.valueType === 'percentage' ? total : 1);
      return acc;
    }, 0);

    return (
      <section
        className={classnames({
          secondary: secondary,
          billing: true,
          printFill: printFill,
        })}
      >
        {ownerView && (
          <label className='billing_status'>
            Status:
            <span className={rental.billingStatus}>{rental.billingStatus}</span>
          </label>
        )}

        <h3>Billing</h3>

        <div className='charges'>
          {rental.billing.rental_item_total_before_discount > 0 && (
            <div>
              <label>Item(s) Total</label>
              <CurrencyLabel
                value={rental.billing.rental_item_total_before_discount}
              />
            </div>
          )}
          {rental.billing.rental_add_on_total > 0 && (
            <div>
              <label>Purchase(s) Total</label>
              <CurrencyLabel value={rental.billing.rental_add_on_total} />
            </div>
          )}

          {rental.billing.rental_bundle_total > 0 && (
            <div>
              <label>Bundle(s) Total</label>
              <CurrencyLabel value={rental.billing.rental_bundle_total} />
            </div>
          )}

          {rental.billing.staffing_total > 0 && (
            <div>
              <label>Staffing Total</label>
              <CurrencyLabel value={rental.billing.staffing_total} />
            </div>
          )}

          {+rental.billing.total_delivery_cost > 0 && (
            <div>
              <label>Delivery</label>
              <CurrencyLabel value={rental.billing.total_delivery_cost} />
            </div>
          )}

          {rental.damageWaiverFeeTotal > 0 && (
            <div>
              <label>
                Damage Waiver
                {rental.damageWaiverExempt && (
                  <span>
                    <a
                      className='info'
                      data-tip
                      data-for='damageWaiver-tip'
                      data-event='click'
                      data-event-off='mouseleave'
                    >
                      <HelpCircle />
                    </a>
                    <ReactTooltip
                      id='damageWaiver-tip'
                      class='tooltip bottom'
                      place='bottom'
                      effect='solid'
                    >
                      <p>Some of your items are damage waiver fee free.</p>
                    </ReactTooltip>
                  </span>
                )}
              </label>
              <CurrencyLabel value={rental.damageWaiverFeeTotal} />
            </div>
          )}
          {slim && (
            <div>
              <label>Fee Total</label>
              <CurrencyLabel value={taxableFeeTotal} />
            </div>
          )}
          {Object.keys(rental.itemFees).map((feeId) => (
            <div>
              <label>{rental.itemFees[feeId].name}</label>
              <CurrencyLabel value={rental.itemFees[feeId].feeAmount} />
            </div>
          ))}
          {/*  Exclude Item specific fees, they are coming from redux  (filter function down here)*/}
          {!slim &&
            rental.taxableFees
              .filter((fee) => fee.feeTargetType !== 'item')
              .map((fee, i) => (
                <div key={i}>
                  <label>
                    {fee.name === 'damage_fee' ? 'Damage Fee' : fee.name}
                    {fee.valueType === 'percentage' && (
                      <span>
                        {' ('}
                        <FormattedNumber value={fee.amount} style='percent' />
                        {')'}
                      </span>
                    )}
                  </label>
                  <CurrencyLabel
                    value={
                      fee.amount * (fee.valueType === 'percentage' ? total : 1)
                    }
                  />
                </div>
              ))}

          {rental.pickListDamageFees.map((fee, i) =>
            fee.amount === 0 ? null : (
              <div key={i}>
                <label>
                  {fee.name}
                  {fee.valueType === 'percentage' && (
                    <span>
                      {' ('}
                      <FormattedNumber value={fee.amount} style='percent' />
                      {')'}
                    </span>
                  )}
                </label>
                <CurrencyLabel
                  value={
                    fee.amount * (fee.valueType === 'percentage' ? total : 1)
                  }
                />
              </div>
            )
          )}
          {rental.pickListAccessoryFees.map((fee, i) =>
            fee.amount === 0 ? null : (
              <div key={i}>
                <label>
                  {fee.name}
                  {fee.valueType === 'percentage' && (
                    <span>
                      {' ('}
                      <FormattedNumber value={fee.amount} style='percent' />
                      {')'}
                    </span>
                  )}
                </label>
                <CurrencyLabel
                  value={
                    fee.amount * (fee.valueType === 'percentage' ? total : 1)
                  }
                />
              </div>
            )
          )}
          {Object.keys(rental.inventoryDiscounts).map((discountId) => (
            <div>
              <label>
                Discount {rental.inventoryDiscounts[discountId].name}
              </label>
              <CurrencyLabel
                value={-rental.inventoryDiscounts[discountId].discountedAmount}
              />
            </div>
          ))}
          {slim ? (
            <div>
              <label>Discount Total</label>
              {rental.discount_total && (
                <CurrencyLabel value={rental.discount_total} />
              )}
            </div>
          ) : (
            rental.discounts.map((discount, i) =>
              discount.discountTargetType === 'global' ? (
                <div key={i}>
                  <label>
                    {discount.name}
                    {discount.valueType === 'percentage' && (
                      <span>
                        {' ('}
                        <FormattedNumber
                          value={discount.amount}
                          style='percent'
                          minimumFractionDigits={2}
                          minimumSignificantDigits={3}
                        />
                        {')'}
                      </span>
                    )}
                  </label>
                  {discount.valueType === 'percentage' ? (
                    <CurrencyLabel value={-discount.amount * total} />
                  ) : (
                    <CurrencyLabel value={-discount.amount} />
                  )}
                </div>
              ) : null
            )
          )}

          <div className='subtotal'>
            <label>Subtotal</label>
            <CurrencyLabel
              value={rental.subTotal - rental.creditCardFeeTotal}
            />
          </div>

          <div>
            <label>Tax</label>
            <span>
              {rental.taxExempt && (
                <label className='caption'>Tax Exempt</label>
              )}
              <CurrencyLabel value={rental.billing.tax_total} />
            </span>
          </div>

          {!slim && !rental.taxExempt &&
            rental.salesTaxes.map((salesTax) => {
              if (salesTax.taxPercent > 0 || salesTax.shortName.includes('unincorporated')) {
                const taxVal =
                  parseInt(rental.billing.tax_total) > 0
                    ? salesTax.taxPercent *
                      (rental.subTotalForTax - rental.creditCardFeeTotal)
                    : 0;

                const renderSalesTaxValue = () => {
                  if (salesTax.taxType === 'custom') {
                    return checkPermission('view_pricing_billing') ? (
                      <FormattedNumber
                        value={salesTax.taxPercent}
                        minimumFractionDigits={2}
                        maximumFractionDigits={20}
                        style='percent'
                      />
                    ) : (
                      '-'
                    );
                  }

                  return <CurrencyLabel value={taxVal} />;
                };

                return (
                  <div key={salesTax.id}>
                    <label className='indent'>
                      {salesTax.taxType !== 'district' && salesTax.name}
                    </label>
                    <span>{renderSalesTaxValue()}</span>
                  </div>
                );
              } else {
                return null;
              }
            })}
          {rental.creditCardFees.map((fee, i) => (
            <div key={i}>
              <label>
                {fee.name}
                {fee.valueType === 'percentage' && (
                  <span>
                    (
                    <FormattedNumber
                      value={fee.amount}
                      style='percent'
                      minimumFractionDigits={2}
                      minimumSignificantDigits={3}
                    />
                    )
                  </span>
                )}
              </label>

              <CurrencyLabel
                value={
                  fee.amount * (fee.valueType === 'percentage' ? total : 1)
                }
              />
            </div>
          ))}

          <div>
            <label>Additional Taxes</label>
            <span>
              <CurrencyLabel value={rental.billing.total_custom_tax} />
            </span>
          </div>
          {!slim && rental.tapgoodsFeeTotal > 0 && (
            <div>
              <label>TapGoods Processing Fee</label>
              <span>
                <CurrencyLabel value={rental.tapgoodsFeeTotal} />
              </span>
            </div>
          )}
          <div className='total'>
            <label>Rental Total</label>
            <CurrencyLabel value={rental.billing.overall_total} />
          </div>
        </div>
        {!slim && rental.payments && rental.payments.length > 0 && (
          <div className='payments'>
            {rental.payments.map((payment) => (
              <DetailedAmountPaid
                key={payment.id}
                payment={payment}
                ownerView={ownerView}
              />
            ))}

            {rental.refunds.map((refund) => (
              <DetailedAmountRefunded
                key={refund.id}
                refund={refund}
                ownerView={ownerView}
              />
            ))}

            <div className='total'>
              <label>Amount Remaining</label>
              <CurrencyLabel value={rental.billing.amount_remaining} />
            </div>
          </div>
        )}
        {!slim && (
          <div className='earnings'>
            {ownerView && rental.processingFeeTotal > 0 && (
              <div>
                <label>Processing Fee Total</label>
                <CurrencyLabel value={rental.processingFeeTotal || 0} />
              </div>
            )}
            {ownerView && rental.tgBusinessFee > 0 && (
              <div>
                <label>TapGoods Referral Fee</label>
                <CurrencyLabel value={rental.tgBusinessFee} />
              </div>
            )}

            {ownerView && rental.billing.expenseTotal > 0 && (
              <div>
                <label>Expenses</label>
                <CurrencyLabel value={rental.billing.expenseTotal} />
              </div>
            )}

            {ownerView && !printFill && (
              <div className='total'>
                <label>Net Earnings</label>
                <CurrencyLabel value={netEarnings} />
              </div>
            )}
          </div>
        )}

        {!slim && rental.expenses.length > 0 && (
          <div className='expenses'>
            <h4>Internal Expenses</h4>
            {rental.expenses.map((expense) => (
              <div key={expense.id}>
                <label>{expense.name}</label>
                <CurrencyLabel value={expense.amount} />
              </div>
            ))}
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const propsRental = ownProps.rental;
  const reduxRental = state.rental.rental;
  const business = state.business;
  const checkPermission = selectCheckPermission(state);

  return {
    rental: propsRental || reduxRental,
    business: business.business,
    checkPermission,
  };
};

export default connect(mapStateToProps, actions)(RentalBilling);
