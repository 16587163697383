import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as actions from 'Actions';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import { selectCheckPermission } from 'HelperFunctions/locations';
import MobileSignedInNav from './MobileSignedInNav';
import MessagesTab from './MessagesTab';
import { saveLocationId, saveBusinessId } from 'Api';
import { history } from 'Components/Routes';
import { cpHostName } from 'HelperFunctions/locations';
import { store } from '../Root';
import { setMultilocationAllLocations } from 'Actions/dashboardActionCreators';

import {
  ArrowUp,
  ArrowDown,
  CheckingIn,
  CheckingOut,
  AddCircle,
  Team,
  Company,
  Venue,
  Items,
  Accessories,
  Addons,
  TickMark,
  Bundles,
  Count,
  Barcode,
  Maintenance,
  AllRentals,
  Pending,
  Quotes,
  Reservations,
  CheckedOut,
  CheckedIn,
  Closed,
  Cancelled,
  Drafts,
  Templates,
  Export,
  Calendar,
  Profit,
  Receipt,
  Business,
  RentalSettings,
  Connect,
  WithSubRentals,
  Payment,
  Email,
  LegalDocument,
  Alert,
  Roles,
  TruckDropOff,
  Map,
  GpsMarker,
  TapGoods,
  Layout,
  Styling,
  Pages,
  MenuIcon,
  MessageBubble,
  ThumbsUp,
  Footer,
  NowstaLogo,
  QuickbooksCircle,
  CartIcon,
  OrderBag,
  Zapier,
  Contacts,
  Clock,
  Proposals,
  Print,
  PurchaseOrderFilled,
} from 'Utils/SvgIcons';
import Imgix from 'react-imgix';
import ScrollArea from 'Utils/react-scrollbar';
import { paymentsUrl } from 'HelperFunctions/urls';
import { reportLinkColumns } from 'HelperFunctions/menuLinkHelper';
import { getDashboardNavLinkPath } from '../utils/AtAGlanceLink';
import converter from 'json-style-converter/es5';
import { PERMISSION_ERROR_MESSAGES } from 'HelperFunctions/permissions';
import { useApolloClient } from '@apollo/client';
import { pickListManagerFeatureName } from '../../helper_functions/pickListFunctions';
import { BlueClock } from '../utils/SvgIcons';
import originalTerminologies from '../../helper_functions/originalTerminologies';
import { useTheme } from 'styled-components';

const SignedInNav = ({
  business,
  location,
  user,
  signOutUser,
  checkPermission,
  employeeLocationRelationship,
  multilocationAllLocations,
  fetchNotificationAllCount,
  setMultilocationAllLocations,
  fetchLocation,
  fetchBusiness,
  fetchTerminologies,
  fetchOriginalTerminologies,
  setNewUser,
  setErrors,
  sendChurnZeroEvent,
  switchLocation,
}) => {
  const [dashboard, setDashboard] = useState({ value: 1 });
  const [mobile, setMobile] = useState({ open: false });
  const [events, setEvents] = useState({ open: false });
  const [inventory, setInventory] = useState({ open: false });
  const [contacts, setContacts] = useState({ open: false });
  const [operations, setOperations] = useState({ open: false });
  const [admin, setAdmin] = useState({ open: false });
  const [profile, setProfile] = useState({ open: false });
  const [businesses, setBusinesses] = useState({ open: false });
  const [client, setClient] = useState(useApolloClient());
  const [locationSearchValue, setLocationSearchValue] = useState('');

  const theme = useTheme();

  useEffect(() => {
    let locationId = store.getState().location.location.id;
    store.subscribe(() => {
      let newLocationId = store.getState().location.location.id;
      if (locationId != newLocationId) {
        locationId = newLocationId;
        fetchTerminologies(false, locationId);
        fetchOriginalTerminologies(false);
      }
    });
  }, []);

  const mobileSignOut = () => {
    toggleMobile();
    signOutUser();
  };

  const toggleMobile = () => {
    setMobile({ open: !mobile.open });
  };

  const shouldRender = () => {
    return checkPermission('operations_view');
  };

  const determineOffset = (menu) => {
    let offset = 0;
    switch (menu) {
      case 'events':
        offset++;
      /* falls through */
      case 'inventory':
        if (
          employeeLocationRelationship?.roleKeys?.includes('devops') &&
          (checkPermission('view_inventory') ||
            checkPermission('edit_add_inventory'))
        ) {
          offset++;
        }
      /* falls through */
      case 'contacts':
        if (
          checkPermission('view_contacts') ||
          checkPermission('edit_add_contacts')
        ) {
          offset++;
        }
      /* falls through */
      case 'operations':
        offset++;
      /* falls through */
      case 'admin':
        if (
          checkPermission('view_reports') ||
          checkPermission('view_edit_settings')
        ) {
          offset++;
        }
      /* falls through */
      case 'messages':
        offset++;
      /* falls through */
      default:
    }
    return 'offset' + offset;
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case 'mobile':
        setMobile(value);
        break;
      case 'events':
        setEvents(value);
        break;
      case 'inventory':
        setInventory(value);
        break;
      case 'contacts':
        setContacts(value);
        break;
      case 'operations':
        setOperations(value);
        break;
      case 'admin':
        setAdmin(value);
        break;
      case 'profile':
        setProfile(value);
        break;
      case 'businesses':
        setBusinesses(value);
        break;
      case 'client':
        setClient(value);
        break;
    }
  };

  const toggleEventsMenu = (event) => {
    setEvents({
      open: !events.open,
      anchorEl: event.currentTarget,
    });
  };
  const leaveEventsMenu = (event) => {
    setEvents({
      open: false,
    });
  };

  const toggleInventoryMenu = (event) => {
    setInventory({
      open: !inventory.open,
      anchorEl: event.currentTarget,
    });
  };
  const leaveInventoryMenu = (event) => {
    setInventory({
      open: false,
    });
  };

  const toggleContactsMenu = (event) => {
    setContacts({
      open: !contacts.open,
      anchorEl: event.currentTarget,
    });
  };
  const leaveContactsMenu = (event) => {
    setContacts({
      open: false,
    });
  };

  const toggleOperationsMenu = (event) => {
    setOperations({
      open: !operations.open,
      anchorEl: event.currentTarget,
    });
  };
  const leaveOperationsMenu = (event) => {
    setOperations({
      open: false,
    });
  };

  const toggleAdminMenu = (event) => {
    setAdmin({
      open: !admin.open,
      anchorEl: event.currentTarget,
    });
  };
  const leaveAdminMenu = (event) => {
    setAdmin({
      open: false,
    });
  };

  const toggleProfileMenu = (event) => {
    setProfile({
      open: !profile.open,
      anchorEl: event.currentTarget,
    });
  };
  const leaveProfileMenu = (event) => {
    setProfile({
      open: false,
    });
  };

  const toggleBusinessesMenu = (event) => {
    setBusinesses({
      open: !businesses.open,
      anchorEl: event.currentTarget,
    });
  };
  const leaveBusinessesMenu = (event) => {
    setBusinesses({
      open: false,
    });
  };

  const selectAllLocations = (businessId) => {
    setMultilocationAllLocations(true);
    fetchNotificationAllCount(true);
    if (
      !window.location.pathname.startsWith('/todos') &&
      !window.location.pathname.startsWith('/dashboard') &&
      !window.location.pathname.startsWith('/payments')
    )
      history.push('/todos');
  };

  const selectBusiness = (locationId, businessId) => {
    switchLocation(locationId, businessId);
    history.push('/todos');
  };

  const addNewBusiness = () => {
    setNewUser({ ...user, software_tier: 'all' });
    window.open('/start', '_blank');
  };

  const permissionGate = (event, permission, errorMessage) => {
    if (!checkPermission(permission)) {
      event.preventDefault();
      setErrors(errorMessage);
    }
  };

  const myProfileSupportOnClick = () => {
    const eventName = 'My Profile Support Clicked';
    sendChurnZeroEvent(eventName);
  };

  const renderLinks = (links) => {
    let linkList = links.map((reportLink, reportIndex) => (
      <li key={reportLink.id ?? reportIndex} name={reportLink.name}>
        <Link
          to={reportLink.to}
          id={`nav_admin_reports_${reportLink.id ??
            converter.camelToSnakeCase(reportLink.to)}`}
        >
          {reportLink.icon}
          {reportLink.name}
        </Link>
      </li>
    ));

    const protectedStuff = [
      'Payments',
      'Sales Tax',
      'Profit',
      'Best Customers',
      'Popular Items',
      'Inventory',
      'Total Order Volume',
      'Commission',
      'Revenue',
      'General Ledger',
    ];

    if (!checkPermission('access_quickbooks_connection_page_reports'))
      linkList = linkList.filter((l) => l.props.name !== 'QuickBooks');
    if (!checkPermission('export_reports'))
      linkList = linkList.filter((l) => !protectedStuff.includes(l.props.name));

    return linkList;
  };

  const {
    venuePlural,
    venueSingular,
    parentOrderSingular,
    checkedInSingular,
    checkedOutSingular,
    checkingInSingular,
    checkingOutSingular,
  } = originalTerminologies();

  const { locations } = user;

  const creditTermsEnabled = business.permissions.creditTermsEnabled;
  const otherLocations = locations.filter(
    (l) => multilocationAllLocations || l.id !== location.id
  );

  const propertyHasSearchValue = (property) =>
    property.toLowerCase().includes(locationSearchValue.toLowerCase());

  const filteredLocations = otherLocations.filter((l) => {
    return locationSearchValue?.length
      ? propertyHasSearchValue(l.name) || propertyHasSearchValue(l.locationCode)
      : l;
  });

  const canViewMultilocation =
    checkPermission('view_edit_ml_settings') ||
    checkPermission('view_edit_ml_team') ||
    checkPermission('view_edit_ml_locations') ||
    checkPermission('view_edit_ml_account');

  return (
    <MediaBreakpoints
      desktop={
        <div className='header'>
          <div className='mainMenu'>
            {otherLocations.length === 0 && (
              <Link to='/todos'>{location.name}</Link>
            )}
            {otherLocations.length > 0 && (
              <div
                className='companySelect'
                onMouseEnter={toggleBusinessesMenu}
                onMouseLeave={leaveBusinessesMenu}
                onClick={toggleBusinessesMenu}
              >
                {multilocationAllLocations ? (
                  <Link to='/todos'>{business.name} (All Locations)</Link>
                ) : (
                  <Link to='/todos'>
                    {location.locationCode && (
                      <span
                        className='badge'
                        style={{
                          backgroundColor:
                            location.locationColor || theme.darkGray,
                        }}
                      >
                        {location.locationCode}
                      </span>
                    )}
                    {location.name}
                  </Link>
                )}
                {businesses.open ? <ArrowUp /> : <ArrowDown />}
                {businesses.open && (
                  <div className='subMenu'>
                    <div>
                      <div>
                        <h4>My Accounts</h4>
                      </div>
                      <ScrollArea speed={0.8} horizontal={false}>
                        <input
                          value={locationSearchValue}
                          className='searchInput'
                          type='search'
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            setLocationSearchValue(e.target.value)
                          }
                        />
                        <ul>
                          {!multilocationAllLocations && (
                            <li key={-1}>
                              <a
                                onClick={() =>
                                  selectAllLocations(location.businessId)
                                }
                              >
                                {business.name} (All Locations)
                              </a>
                            </li>
                          )}
                          {filteredLocations.map((location, index) => (
                            <li key={index}>
                              <a
                                onClick={() =>
                                  selectBusiness(
                                    location.id,
                                    location.businessId
                                  )
                                }
                              >
                                {location.locationCode ? (
                                  <span>
                                    <span
                                      className='badge'
                                      style={{
                                        backgroundColor:
                                          location.locationColor ||
                                          theme.darkGray,
                                      }}
                                    >
                                      {location.locationCode}
                                    </span>
                                    {location.name}
                                  </span>
                                ) : (
                                  location.fullName
                                )}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </ScrollArea>
                    </div>
                  </div>
                )}
              </div>
            )}
            <ul>
              {/* Orders */}

              <li>
                <NavLink
                  id='nav_dashboard'
                  to={getDashboardNavLinkPath(employeeLocationRelationship)}
                  activeClassName='selected'
                  isActive={(match, location) => {
                    if (
                      match ||
                      (!multilocationAllLocations &&
                        location.pathname.includes('/at-a-glance')) ||
                      location.pathname.includes('dashboard') ||
                      location.pathname.includes('payments/recent') ||
                      location.pathname.includes('payments/balanceRemaining') ||
                      location.pathname.includes('payments/finalBalanceDue')
                    ) {
                      return true;
                    }
                  }}
                >
                  Dashboard
                </NavLink>
              </li>
              {multilocationAllLocations ? (
                <li
                  className={classnames({
                    col2: !shouldRender(),
                    col3: shouldRender(),
                    [determineOffset('events')]: true,
                    disabled: true,
                  })}
                >
                  Orders
                </li>
              ) : (
                <li
                  className={classnames({
                    eventsMenu: true,
                    col2: !shouldRender(),
                    col3: shouldRender(),
                    [determineOffset('events')]: true,
                  })}
                  onMouseEnter={toggleEventsMenu}
                  onMouseLeave={leaveEventsMenu}
                  onClick={toggleEventsMenu}
                >
                  <NavLink
                    id='nav_orders'
                    to='/orders'
                    activeClassName='selected'
                  >
                    Orders
                  </NavLink>
                  {events.open && (
                    <div className='subMenu'>
                      <div>
                        <div>
                          <h4>Orders</h4>
                          {shouldRender() && (
                            <ul>
                              {(business.permissions.secondaryOrdersEnabled ||
                                business.permissions.eventGroupingEnabled) && (
                                <li>
                                  <Link
                                    id='nav_orders_orders_new_events'
                                    className='add'
                                    to='/orders/events/new'
                                  >
                                    <AddCircle />
                                    Create New Event
                                  </Link>
                                </li>
                              )}
                              {business.permissions.secondaryOrdersEnabled && (
                                <li>
                                  <Link
                                    id='nav_orders_orders_new_events'
                                    className='add'
                                    to='/orders/parent-orders/new'
                                  >
                                    <AddCircle />
                                    Create {parentOrderSingular}
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link
                                  id='nav_orders_orders_new_orders'
                                  className='add'
                                  to='/orders/rentals/new'
                                >
                                  <AddCircle />
                                  Create New Order
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_orders_orders_new_template'
                                  className='add'
                                  to='/orders/rentals/newTemplate'
                                >
                                  <AddCircle />
                                  Create New Template
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                        <ul>
                          {shouldRender() &&
                            (business.permissions.secondaryOrdersEnabled ||
                              business.permissions.eventGroupingEnabled) && (
                              <li>
                                <Link
                                  id='nav_orders_events_all'
                                  to='/orders/events/all'
                                >
                                  <AllRentals />
                                  All Events
                                </Link>
                              </li>
                            )}
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_orders_orders_all_orders'
                                to='/orders/rentals/all'
                              >
                                <AllRentals />
                                All Orders
                              </Link>
                            </li>
                          )}
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_orders_orders_open_orders'
                                to='/orders/rentals/open'
                              >
                                <AllRentals />
                                Open Orders
                              </Link>
                            </li>
                          )}
                          {checkPermission('operations_view') && (
                            <li>
                              <Link
                                id='nav_orders_orders_pending'
                                to='/orders/rentals/pending'
                              >
                                <Pending />
                                Pending
                              </Link>
                            </li>
                          )}

                          {(shouldRender() ||
                            checkPermission('view_reservations_quotes')) && (
                            <li>
                              <Link
                                id='nav_orders_orders_quotes'
                                to='/orders/rentals/quotes'
                              >
                                <Quotes />
                                Quotes
                              </Link>
                            </li>
                          )}

                          {(shouldRender() ||
                            checkPermission('view_reservations_quotes')) && (
                            <li>
                              <Link
                                id='nav_orders_orders_reservations'
                                to='/orders/rentals/reservations'
                              >
                                <Reservations />
                                Reservations
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              id='nav_orders_orders_checked_out'
                              to='/orders/rentals/checkedOut'
                            >
                              <CheckedOut />
                              {checkedOutSingular}
                            </Link>
                          </li>
                          <li>
                            <Link
                              id='nav_orders_orders_checked_in'
                              to='/orders/rentals/checkedIn'
                            >
                              <CheckedIn />
                              {checkedInSingular}
                            </Link>
                          </li>
                          {(shouldRender() ||
                            checkPermission('view_closed')) && (
                            <li>
                              <Link
                                id='nav_orders_orders_closed_orders'
                                to='/orders/rentals/closed'
                              >
                                <Closed />
                                Closed Orders
                              </Link>
                            </li>
                          )}
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_orders_orders_cancelled_orders'
                                to='/orders/rentals/cancelled'
                              >
                                <Cancelled />
                                Cancelled Orders
                              </Link>
                            </li>
                          )}
                        </ul>

                        <ul>
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_orders_orders_alerts'
                                to='/orders/rentals/alerts'
                              >
                                <Alert />
                                Alerts
                              </Link>
                            </li>
                          )}
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_orders_orders_drafts'
                                to='/orders/rentals/drafts'
                              >
                                <Drafts />
                                Drafts
                              </Link>
                            </li>
                          )}
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_orders_orders_templates'
                                to='/orders/rentals/templates'
                              >
                                <Templates />
                                Templates
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                      {shouldRender() && (
                        <div>
                          <div>
                            <h4>Subrentals</h4>
                          </div>
                          <ul>
                            {/* <li>
                                <Link
                                  id="nav_orders_suborders_orders_from_connect"
                                  to="/orders/subrentals/fromConnect"
                                >
                                  <Connect />
                                  Orders from Connect
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id="nav_orders_suborders_orders_with_suborders"
                                  to="/orders/subrentals/withSubrentals"
                                >
                                  <WithSubRentals />
                                  Orders with Subrentals
                                </Link>
                              </li> */}
                            <li>
                              <Link
                                id='nav_orders_all_subrentals'
                                to='/orders/subrentals/all'
                              >
                                <WithSubRentals />
                                All Subrentals
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </li>
              )}

              {/* Inventory */}
              {user &&
                (checkPermission('view_inventory') ||
                  checkPermission('edit_add_inventory')) &&
                (multilocationAllLocations ? (
                  <li
                    className={classnames({
                      inventoryMenu: true,
                      col2: !checkPermission('edit_add_inventory'),
                      col3: checkPermission('edit_add_inventory'),
                      [determineOffset('inventory')]: true,
                      disabled: true,
                    })}
                    onMouseEnter={toggleInventoryMenu}
                    onMouseLeave={leaveInventoryMenu}
                    onClick={toggleInventoryMenu}
                  >
                    Inventory
                  </li>
                ) : (
                  <li
                    className={classnames({
                      inventoryMenu: true,
                      col2: !checkPermission('edit_add_inventory'),
                      col3: checkPermission('edit_add_inventory'),
                      [determineOffset('inventory')]: true,
                    })}
                    onMouseEnter={toggleInventoryMenu}
                    onMouseLeave={leaveInventoryMenu}
                    onClick={toggleInventoryMenu}
                  >
                    <NavLink
                      id='nav_inventory'
                      to='/inventory'
                      activeClassName='selected'
                    >
                      Inventory
                    </NavLink>
                    {inventory.open && (
                      <div className='subMenu'>
                        <div>
                          <div>
                            <h4>Inventory</h4>
                            {checkPermission('edit_add_inventory') && (
                              <ul>
                                <li>
                                  <Link
                                    id='nav_inventory_add_item'
                                    className='add'
                                    to='/inventory/items/new'
                                  >
                                    <AddCircle />
                                    Add Item
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    id='nav_inventory_add_accessory'
                                    className='add'
                                    to='/inventory/accessories/new'
                                  >
                                    <AddCircle />
                                    Add Accessory
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    id='nav_inventory_add_purchase'
                                    className='add'
                                    to='/inventory/purchases/new'
                                  >
                                    <AddCircle />
                                    Add{' '}
                                    {originalTerminologies().purchaseSingular}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    id='nav_inventory_add_bundle'
                                    className='add'
                                    to={
                                      business.permissions.bundlesEnabled
                                        ? '/inventory/bundles/new'
                                        : '/upgrade/bundles'
                                    }
                                  >
                                    <AddCircle />
                                    Add Bundle
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                          <ul>
                            <li>
                              <Link
                                id='nav_inventory_calendar'
                                to='/inventory/calendar'
                              >
                                <Calendar />
                                Calendar
                              </Link>
                            </li>
                            <li>
                              <Link
                                id='nav_inventory_items'
                                to='/inventory/items'
                              >
                                <Items />
                                Items
                              </Link>
                            </li>
                            <li>
                              <Link
                                id='nav_inventory_accessories'
                                to='/inventory/accessories'
                              >
                                <Accessories />
                                Accessories
                              </Link>
                            </li>
                            <li>
                              <Link
                                id='nav_inventory_purchases'
                                to='/inventory/purchases'
                              >
                                <Addons />
                                {originalTerminologies().purchasePlural}
                              </Link>
                            </li>
                            <li>
                              <Link
                                id='nav_inventory_bundles'
                                to={
                                  business.permissions.bundlesEnabled
                                    ? '/inventory/bundles'
                                    : '/upgrade/bundles'
                                }
                              >
                                <Bundles />
                                Bundles
                              </Link>
                            </li>
                            {checkPermission('view_inventory_count') && (
                              <li>
                                <Link
                                  id='nav_inventory_inventory_count'
                                  to='/inventory/count/items'
                                >
                                  <Count />
                                  Inventory Count
                                </Link>
                              </li>
                            )}
                            
                            {checkPermission('edit_add_inventory') && (
                              <li>
                                <Link
                                  id='nav_inventory_inventory_count'
                                  to='/inventory/moveSerializedInventory'
                                >
                                  <Barcode />
                                  Move Serialized Inventory
                                </Link>
                              </li>
                            )}
                            {checkPermission(
                              'access_to_inventory_maintenance'
                            ) && (
                              <li>
                                <Link
                                  id='nav_inventory_maintenance'
                                  to='/inventory/maintenance'
                                >
                                  <Maintenance />
                                  Maintenance
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link
                                id='nav_inventory_purchase_orders'
                                to={
                                  business.permissions.purchaseOrdersEnabled
                                    ? '/inventory/purchase_orders'
                                    : '/upgrade/purchaseOrders'
                                }
                              >
                                <PurchaseOrderFilled />
                                Purchase Orders
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                ))}

              {/* Contacts */}
              {user &&
                (checkPermission('view_contacts') ||
                  checkPermission('edit_add_contacts')) &&
                (multilocationAllLocations ? (
                  <li
                    className={classnames({
                      contactsMenu: true,
                      col1: !checkPermission('edit_add_contacts'),
                      col2: checkPermission('edit_add_contacts'),
                      [determineOffset('contacts')]: true,
                      disabled: true,
                    })}
                    onMouseEnter={toggleContactsMenu}
                    onMouseLeave={leaveContactsMenu}
                    onClick={toggleContactsMenu}
                  >
                    Contacts
                  </li>
                ) : (
                  <li
                    className={classnames({
                      contactsMenu: true,
                      col1: !checkPermission('edit_add_contacts'),
                      col2: checkPermission('edit_add_contacts'),
                      [determineOffset('contacts')]: true,
                    })}
                    onMouseEnter={toggleContactsMenu}
                    onMouseLeave={leaveContactsMenu}
                    onClick={toggleContactsMenu}
                  >
                    <NavLink
                      id='nav_contacts'
                      to='/contacts'
                      activeClassName='selected'
                    >
                      Contacts
                    </NavLink>
                    {contacts.open && (
                      <div className='subMenu'>
                        <div>
                          <div>
                            <h4>Contacts</h4>
                            {checkPermission('edit_add_contacts') && (
                              <ul>
                                <li>
                                  <Link
                                    id='nav_contacts_add_customer'
                                    className='add'
                                    to='/contacts/customers/new'
                                    onClick={(event) =>
                                      permissionGate(
                                        event,
                                        'add_new_contacts',
                                        PERMISSION_ERROR_MESSAGES[
                                          'add_new_contacts'
                                        ]
                                      )
                                    }
                                  >
                                    <AddCircle />
                                    Add Customer
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    id='nav_contacts_add_company'
                                    className='add'
                                    to='/contacts/companies/new'
                                    onClick={(event) =>
                                      permissionGate(
                                        event,
                                        'add_new_contacts',
                                        PERMISSION_ERROR_MESSAGES[
                                          'add_new_contacts'
                                        ]
                                      )
                                    }
                                  >
                                    <AddCircle />
                                    Add Company
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    id='nav_contacts_add_venue'
                                    className='add'
                                    to='/contacts/venues/new'
                                    onClick={(event) =>
                                      permissionGate(
                                        event,
                                        'add_new_contacts',
                                        PERMISSION_ERROR_MESSAGES[
                                          'add_new_contacts'
                                        ]
                                      )
                                    }
                                  >
                                    <AddCircle />
                                    Add {venueSingular}
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                          <ul>
                            <li>
                              <Link
                                id='nav_contacts_customers'
                                to='/contacts/customers'
                              >
                                <Team />
                                Customers
                              </Link>
                            </li>
                            <li>
                              <Link
                                id='nav_contacts_companies'
                                to='/contacts/companies'
                              >
                                <Company />
                                Companies
                              </Link>
                            </li>
                            <li>
                              <Link
                                id='nav_contacts_venues'
                                to='/contacts/venues'
                              >
                                <Venue />
                                {venuePlural}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                ))}

              {/* Operations */}
              {multilocationAllLocations ? (
                <li
                  className={classnames({
                    operationsMenu: true,
                    col1: true,
                    [determineOffset('operations')]: true,
                    disabled: true,
                  })}
                  onMouseEnter={toggleOperationsMenu}
                  onMouseLeave={leaveOperationsMenu}
                  onClick={toggleOperationsMenu}
                >
                  Operations
                </li>
              ) : (
                <li
                  className={classnames({
                    operationsMenu: true,
                    col1: true,
                    [determineOffset('operations')]: true,
                  })}
                  onMouseEnter={toggleOperationsMenu}
                  onMouseLeave={leaveOperationsMenu}
                  onClick={toggleOperationsMenu}
                >
                  <NavLink
                    id='nav_operations'
                    to='/operations'
                    activeClassName='selected'
                  >
                    Operations
                  </NavLink>
                  {operations.open && (
                    <div className='subMenu'>
                      {checkPermission('view_routing') &&
                        business.showRoutingAssignments && (
                          <div>
                            <div>
                              <h4>Routing</h4>
                              <ul>
                                <li>
                                  <Link
                                    id='nav_operations_routing_add_truck'
                                    className='add'
                                    to={
                                      business.permissions
                                        .routingAndTrackingEnabled
                                        ? '/admin/settings/routing'
                                        : '/upgrade/routing'
                                    }
                                  >
                                    <AddCircle />
                                    Add Truck
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <ul>
                              <li>
                                <Link
                                  id='nav_operations_routing_schedule'
                                  to={
                                    business.permissions
                                      .routingAndTrackingEnabled
                                      ? '/operations/routing/calendar'
                                      : '/upgrade/routing'
                                  }
                                >
                                  <Calendar />
                                  Calendar
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={
                                    business.permissions
                                      .routingAndTrackingEnabled
                                      ? '/operations/routing/orders'
                                      : '/upgrade/routing'
                                  }
                                >
                                  <AllRentals />
                                  Orders
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_operations_routing_map'
                                  to={
                                    business.permissions
                                      .routingAndTrackingEnabled
                                      ? '/operations/routing/map'
                                      : '/upgrade/routing'
                                  }
                                >
                                  <Map />
                                  Map
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_operations_routing_gps'
                                  to={
                                    business.permissions.gpsEnabled &&
                                    business.permissions
                                      .routingAndTrackingEnabled
                                      ? '/operations/routing/gps'
                                      : '/upgrade/gps'
                                  }
                                >
                                  <GpsMarker />
                                  GPS
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}

                      <div>
                        <div>
                          <h4>Warehouse</h4>
                        </div>
                        <ul>
                          <li>
                            <Link
                              id='nav_operations_warehouse_checking_out'
                              to='/operations/warehouse/checkingOut'
                            >
                              <CheckingOut />
                              {checkingOutSingular}
                            </Link>
                          </li>
                          <li>
                            <Link
                              id='nav_operations_warehouse_checking_in'
                              to='/operations/warehouse/checkingIn'
                            >
                              <CheckingIn />
                              {checkingInSingular}
                            </Link>
                          </li>
                        </ul>
                      </div>

                      {checkPermission('view_edit_staffing') && (
                        <div>
                          <div>
                            <h4>Staffing</h4>
                            <ul>
                              <li>
                                <Link
                                  id='nav_operations_staffing_add_staff_member'
                                  className='add'
                                  to='/operations/staff/members/new'
                                >
                                  <AddCircle />
                                  Add Staff Member
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <ul>
                            {checkPermission('view_edit_nowsta') && (
                              <li>
                                <Link
                                  id='nav_operations_staffing_nowsta'
                                  to='/operations/staff/nowsta'
                                >
                                  <NowstaLogo color={'#278ADA'} />
                                  Nowsta
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link
                                id='nav_operations_staffing_schedule'
                                to='/operations/staff/calendar'
                              >
                                <Calendar />
                                Schedule
                              </Link>
                            </li>
                            {checkPermission('view_add_remove_staff') && (
                              <li>
                                <Link
                                  id='nav_operations_staffing_staff_members'
                                  to='/operations/staff/members'
                                >
                                  <Team />
                                  Staff Members
                                </Link>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}

                      <div>
                        <div>
                          <h4>Services</h4>
                          <ul>
                            <li>
                              <Link
                                id='nav_operations_staffing_add_staff_member'
                                className='add'
                                to='/operations/services/new'
                              >
                                <AddCircle />
                                Add Service
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <ul>
                          <li>
                            <Link
                              id='nav_operations_staffing_nowsta'
                              to='/operations/services'
                            >
                              <Roles color={'#278ADA'} />
                              Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              id='nav_operations_staffing_schedule'
                              to={
                                business.permissions.recurringServicesEnabled
                                  ? '/operations/recurrences'
                                  : '/upgrade/recurringServices'
                              }
                            >
                              <Clock />
                              Recurrences
                            </Link>
                          </li>
                          <li>
                            <Link
                              id='nav_operations_staffing_nowsta'
                              to={
                                business.permissions.secondaryOrdersEnabled
                                  ? '/operations/labors'
                                  : '/upgrade/secondaryOrders'
                              }
                            >
                              <BlueClock />
                              Labors
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <div>
                          <h4>Calendars</h4>
                        </div>
                        <ul>
                          {shouldRender() && (
                            <li>
                              <Link
                                to={
                                  business.permissions.availabilityCalendars
                                    ? '/operations/calendars/availability'
                                    : '/upgrade/availabilityCalendars'
                                }
                              >
                                <Calendar />
                                Availability
                              </Link>
                            </li>
                          )}
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_operations_calendars_orders'
                                to='/operations/calendars/calendar'
                              >
                                <AllRentals />
                                Orders
                              </Link>
                            </li>
                          )}
                          {shouldRender() && (
                            <li>
                              <Link
                                id='nav_operations_calendars_alerts'
                                to='/operations/calendars/alerts'
                              >
                                <Alert />
                                Alerts
                              </Link>
                            </li>
                          )}

                          <li>
                            <Link
                              id='nav_operations_calendars_drop_off_pick_up'
                              to='/operations/calendars/dropOff-pickUp'
                            >
                              <TruckDropOff />
                              Drop Off/Pick Up
                            </Link>
                          </li>
                        </ul>
                      </div>
                      {checkPermission('view_picklist_manager') && (
                        <div>
                          <div>
                            <h4>{pickListManagerFeatureName(business.id)}</h4>
                          </div>
                          <ul>
                            <li>
                              <Link
                                id='nav_operations_flowsheet_orders'
                                to={
                                  business.permissions.flowSheetEnabled
                                    ? '/operations/flowsheet/orders'
                                    : '/upgrade/flowSheet'
                                }
                              >
                                <RentalSettings />
                                {pickListManagerFeatureName(business.id)}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </li>
              )}

              {/* Admin */}
              {(checkPermission('view_reports') ||
                checkPermission('view_edit_settings')) &&
                (multilocationAllLocations ? (
                  <li
                    className={classnames({
                      adminMenu: true,
                      col3: true,
                      [determineOffset('admin')]: true,
                      disabled: true,
                    })}
                    onMouseEnter={toggleAdminMenu}
                    onMouseLeave={leaveAdminMenu}
                    onClick={toggleAdminMenu}
                  >
                    Admin
                  </li>
                ) : (
                  <li
                    className={classnames({
                      adminMenu: true,
                      col3: true,
                      [determineOffset('admin')]: true,
                    })}
                    onMouseEnter={toggleAdminMenu}
                    onMouseLeave={leaveAdminMenu}
                    onClick={toggleAdminMenu}
                  >
                    <NavLink
                      id='nav_admin'
                      to='/admin'
                      activeClassName='selected'
                    >
                      Admin
                    </NavLink>
                    {admin.open && (
                      <div className='subMenu adminLgMenu'>
                        {checkPermission('view_edit_settings') && (
                          <div>
                            <div>
                              <h4>Storefront</h4>
                            </div>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_storefront_domain_settings'
                                  to='/admin/storefront/domain'
                                >
                                  <RentalSettings />
                                  Domain Settings
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_shop_cart_settings'
                                  to='/admin/storefront/pages/shop'
                                >
                                  <CartIcon />
                                  Shop + Cart
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_themes'
                                  to={
                                    business.permissions
                                      .customizableDesignAndContent
                                      ? '/admin/storefront/themes'
                                      : '/upgrade/customizableDesignAndContent'
                                  }
                                >
                                  <Layout />
                                  Themes
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_styling'
                                  to={
                                    business.permissions
                                      .customizableDesignAndContent
                                      ? '/admin/storefront/styling'
                                      : '/upgrade/customizableDesignAndContent'
                                  }
                                >
                                  <Styling />
                                  Styling
                                </Link>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_storefront_pages'
                                  to='/admin/storefront/pages/shop'
                                >
                                  <Pages />
                                  Pages
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_menu'
                                  to='/admin/storefront/menu'
                                >
                                  <MenuIcon />
                                  Menu
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_testimonials'
                                  to='/admin/storefront/testimonials'
                                >
                                  <MessageBubble />
                                  Testimonials
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_orders'
                                  to='/admin/storefront/pages/orders'
                                >
                                  <OrderBag />
                                  My Orders
                                </Link>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_storefront_social_media'
                                  to='/admin/storefront/socialMedia'
                                >
                                  <ThumbsUp />
                                  Social Media
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_footer'
                                  to='/admin/storefront/footer'
                                >
                                  <Footer />
                                  Footer
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_seo_tracking'
                                  to='/admin/storefront/seo'
                                >
                                  <Profit />
                                  Global SEO + Tracking
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_storefront_wordpress'
                                  to={
                                    business.permissions.wordPressFeatureEnabled
                                      ? '/admin/storefront/wordpress'
                                      : '/upgrade/wordPress'
                                  }
                                >
                                  <RentalSettings />
                                  WordPress Plugin
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}

                        {checkPermission('view_edit_settings') && (
                          <div>
                            <div>
                              <h4>Marketplace</h4>
                            </div>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_marketplace_tapgoods_marketplace'
                                  to='/admin/marketplace/settings'
                                >
                                  <TapGoods />
                                  TapGoods Marketplace
                                </Link>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_marketplace_get_the_look'
                                  to='/admin/marketplace/GettheLook'
                                >
                                  <Accessories />
                                  Get the Look
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}

                        {checkPermission('view_edit_settings') && (
                          <div>
                            <div>
                              <h4>Templates</h4>
                            </div>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_template_proposals'
                                  to='/admin/templates/proposal_templates'
                                >
                                  <Proposals />
                                  Proposals
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}

                        {checkPermission('view_reports') && (
                          <div>
                            <div>
                              <h4>Reports</h4>
                            </div>
                            {reportLinkColumns(
                              business.multilocationReportsEnabled,
                              business.permissions.generalLedgerEnabled,
                              business.permissions.robustAndCustomReports,
                              business.permissions.quickbooksIntegration,
                              business.permissions.npsCustomerSurveys
                            ).map((columnLinks, index) => (
                              <ul key={index}>{renderLinks(columnLinks)}</ul>
                            ))}
                          </div>
                        )}

                        {checkPermission('view_edit_settings') && (
                          <div>
                            <div>
                              <h4>Settings</h4>
                            </div>
                            <ul>
                              {checkPermission('access_settings_location') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_location'
                                    to='/admin/settings/location'
                                  >
                                    <Business />
                                    Location
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_account') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_account'
                                    to='/admin/settings/account'
                                  >
                                    <Receipt />
                                    Account
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_team') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_team'
                                    to='/admin/settings/team'
                                  >
                                    <Team />
                                    Team
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_order') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_order'
                                    to='/admin/settings/order'
                                  >
                                    <RentalSettings />
                                    Order
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_payments') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_payments'
                                    to={paymentsUrl(location.paymentProcessor)}
                                  >
                                    <Payment />
                                    Payments
                                  </Link>
                                </li>
                              )}
                              {checkPermission('gl_mapping_admin') &&
                                checkPermission('access_settings_reports') && (
                                  <li>
                                    <Link
                                      id='nav_admin_settings_reports'
                                      to={
                                        business.permissions
                                          .generalLedgerEnabled
                                          ? '/admin/settings/reports/generalLedger'
                                          : '/upgrade/generalLedger'
                                      }
                                    >
                                      <Profit />
                                      Reports
                                    </Link>
                                  </li>
                                )}
                            </ul>
                            <ul>
                              {checkPermission('access_settings_inventory') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_inventory'
                                    to='/admin/settings/inventory'
                                  >
                                    <Items />
                                    Inventory
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_contacts') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_inventory'
                                    to='/admin/settings/contacts'
                                  >
                                    <Contacts />
                                    Contacts
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_zapier') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_zapier'
                                    to='/admin/settings/zapier'
                                  >
                                    <Zapier />
                                    Zapier
                                  </Link>
                                </li>
                              )}
                              {checkPermission(
                                'access_settings_tg_connect'
                              ) && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_tapgoods_connect'
                                    to='/admin/settings/connectDefaults'
                                  >
                                    <Connect />
                                    TapGoods Connect
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_financial') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_financial_settings'
                                    to='/admin/settings/financialSettings'
                                  >
                                    <RentalSettings />
                                    Financial Settings
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_email') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_email'
                                    to='/admin/settings/email'
                                  >
                                    <Email />
                                    Email
                                  </Link>
                                </li>
                              )}
                            </ul>
                            <ul>
                              {checkPermission('access_settings_legal') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_legal'
                                    to='/admin/settings/legal'
                                  >
                                    <LegalDocument />
                                    Legal
                                  </Link>
                                </li>
                              )}
                              {checkPermission('access_settings_routing') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_routing'
                                    to={
                                      business.permissions
                                        .routingAndTrackingEnabled
                                        ? '/admin/settings/routing'
                                        : '/upgrade/routing'
                                    }
                                  >
                                    <TruckDropOff />
                                    Routing
                                  </Link>
                                </li>
                              )}
                              {checkPermission(
                                'access_settings_terminology'
                              ) && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_terminology'
                                    to='/admin/settings/terminology'
                                  >
                                    <LegalDocument />
                                    Terminology
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link to='/admin/settings/print'>
                                  <Print />
                                  Print
                                </Link>
                              </li>
                              {checkPermission('access_settings_picklist') && (
                                <li>
                                  <Link
                                    id='nav_admin_settings_order'
                                    to='/admin/settings/picklist'
                                  >
                                    <TickMark />
                                    Picklist
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}

                        {checkPermission('access_admin_payment_terms') && (
                          <div>
                            <div>
                              <h4>Payment Terms</h4>
                            </div>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_payment_terms_accounts_receivable'
                                  to={
                                    creditTermsEnabled
                                      ? '/admin/payment_terms/accounts_receivable'
                                      : '/upgrade/creditTerms'
                                  }
                                >
                                  <Export />
                                  Accounts Receivable
                                </Link>
                              </li>
                              <li>
                                <Link
                                  id='nav_admin_payment_terms_statements'
                                  to={
                                    creditTermsEnabled
                                      ? '/admin/payment_terms/statements'
                                      : '/upgrade/creditTerms'
                                  }
                                >
                                  <QuickbooksCircle />
                                  Statements
                                </Link>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_payment_terms_ar_aging'
                                  to={
                                    creditTermsEnabled
                                      ? '/admin/payment_terms/ar_aging'
                                      : '/upgrade/creditTerms'
                                  }
                                >
                                  <Export />
                                  AR Aging
                                </Link>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <Link
                                  id='nav_admin_payment_terms_credit_debit_memos'
                                  to={
                                    creditTermsEnabled
                                      ? '/admin/payment_terms/credit_debit_memos'
                                      : '/upgrade/creditTerms'
                                  }
                                >
                                  <Receipt />
                                  Credit & Debit Memos
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                        {canViewMultilocation &&
                          business.multilocationEnabled && (
                            <div>
                              <div>
                                <h4>Multilocation</h4>
                              </div>
                              <ul>
                                {checkPermission('view_edit_ml_locations') && (
                                  <li>
                                    <Link
                                      id='nav_admin_multilocation_locations'
                                      to='/admin/multilocation/locations'
                                    >
                                      <Business />
                                      Locations
                                    </Link>
                                  </li>
                                )}
                                {checkPermission('view_edit_ml_settings') && (
                                  <li>
                                    <Link
                                      id='nav_admin_multilocation_settings'
                                      to='/admin/multilocation/settings'
                                    >
                                      <RentalSettings />
                                      Settings
                                    </Link>
                                  </li>
                                )}
                              </ul>
                              {checkPermission('view_edit_ml_account') && (
                                <ul>
                                  <li>
                                    <Link
                                      id='nav_admin_multilocation_account'
                                      to='/admin/multilocation/account'
                                    >
                                      <Receipt />
                                      Account
                                    </Link>
                                  </li>
                                </ul>
                              )}
                              {checkPermission('view_edit_ml_team') &&
                                checkPermission(
                                  'access_to_team_multilocation'
                                ) && (
                                  <ul>
                                    <li>
                                      <Link
                                        id='nav_admin_multilocation_team'
                                        to='/admin/multilocation/team'
                                      >
                                        <Team />
                                        Team
                                      </Link>
                                    </li>
                                  </ul>
                                )}
                            </div>
                          )}
                      </div>
                    )}
                  </li>
                ))}

              {/* Messages */}
              {user && user.role !== 'devops' && location && location.id && (
                <MessagesTab />
              )}

              {/* Profile */}
              <li
                className={classnames({
                  profileMenu: true,
                  col3: true,
                })}
                onMouseEnter={toggleProfileMenu}
                onMouseLeave={leaveProfileMenu}
                onClick={toggleProfileMenu}
              >
                <div className='imageWrapper'>
                  {user.picture ? (
                    <Imgix
                      src={user.picture.imgixUrl}
                      alt={user.firstName}
                      height={200}
                      width={200}
                    />
                  ) : (
                    <div>
                      {user.firstName.charAt(0)}
                      {user.lastName.charAt(0)}
                    </div>
                  )}
                </div>
                {profile.open ? <ArrowUp /> : <ArrowDown />}
                {profile.open && (
                  <div className='subMenu'>
                    <div>
                      <div>
                        <h4>My Profile</h4>
                      </div>
                      <ul>
                        <li>
                          <a
                            id='nav_profile_terms_and_conditions'
                            href='//www.tapgoods.com/pro/terms-conditions/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Terms & Conditions
                          </a>
                        </li>
                        <li>
                          <a
                            id='nav_profile_privacy_policy'
                            href='//www.tapgoods.com/pro/privacy-policy/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            id='nav_profile_tapgoods_capital'
                            href='https://tapgoods-capital.webflow.io/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            TapGoods Capital
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link
                            id='nav_profile_get_started'
                            to='/start/get-started'
                          >
                            Get Started
                          </Link>
                        </li>
                        {checkPermission('import_wizard') && (
                          <li>
                            <Link
                              id='nav_import_wizard'
                              to={
                                business.permissions.importWizardEnabled
                                  ? '/importWizard'
                                  : '/upgrade/importWizard'
                              }
                            >
                              Import Wizard
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link
                            id='nav_profile_support'
                            to='/support'
                            onClick={myProfileSupportOnClick}
                          >
                            Support
                          </Link>
                        </li>
                        <li>
                          <a
                            id='nav_profile_schedule_a_demo'
                            target='_blank'
                            href='https://www.tapgoods.com/pro/demo/'
                            rel='noopener noreferrer'
                          >
                            Schedule a Demo
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <Link id='nav_profile_my_profile' to='/profile'>
                            My Profile
                          </Link>
                        </li>
                        {user.role !== 'warehouse_manager' &&
                          user.role !== 'devops' && (
                            <li>
                              <a
                                id='nav_profile_my_storefront'
                                href={cpHostName(location)}
                                target='_blank'
                              >
                                My Storefront
                              </a>
                            </li>
                          )}
                        {user.role !== 'warehouse_manager' &&
                          user.role !== 'devops' && (
                            <li>
                              {location.marketplaceSetting.searchable && (
                                <a
                                  id='nav_profile_my_marketplace'
                                  href={`${business.mpHostname}/shop/search?locationId=${location.id}`}
                                  target='_blank'
                                >
                                  My Marketplace
                                </a>
                              )}
                            </li>
                          )}
                        <li>
                          <a
                            id='nav_profile_sign_out'
                            onClick={signOutUser}
                            className='highlight'
                          >
                            Sign Out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      }
      mobile={
        <MobileSignedInNav
          signOutUser={signOutUser}
          selectBusiness={selectBusiness}
        />
      }
    />
  );
};

SignedInNav.propTypes = {
  signOutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { errors, loading } = state.auth;
  const { business } = state.business;
  const { location } = state.location;
  const { multilocationAllLocations } = state.dashboard;
  const { originalTerminologies, terminologies } = state.terminology;
  const { unreadMessages } = state.conversationList;
  const { user, employeeLocationRelationship } = state.user;
  const checkPermission = selectCheckPermission(state);

  return {
    errors,
    loading,
    business,
    location,
    multilocationAllLocations,
    originalTerminologies,
    terminologies,
    user,
    unreadMessages,
    checkPermission,
    employeeLocationRelationship,
  };
};

export default connect(mapStateToProps, {
  ...actions,
  setMultilocationAllLocations,
})(SignedInNav);
